import React from 'react';

const Schedule = () => (
    <div className='w-11/12 mx-auto my-5 p-5'>
        <h1 className='text-center'>DevFest {process.env.REACT_APP_CHAPTER_NAME} Sessions</h1>
        <div className='grid grid-cols-4 gap-4'>
            <div className='col-span-4'>
                <ul>
                    <li>08:30 - Registration Open</li>
                    <li>10:00 - Keynote</li>
                </ul>
            </div>
            <div className='col-span-1'>
                <ul>
                    <li>11:00 - Session 1</li>
                    <li>12:00 - Session 2</li>
                    <li>13:00 - Session 3</li>
                </ul>
            </div>
            <div className='col-span-1'>
                <ul>
                    <li>11:00 - Session 1</li>
                    <li>12:00 - Session 2</li>
                    <li>13:00 - Session 3</li>
                </ul>
            </div>
            <div className='col-span-1'>
                <ul>
                    <li>11:00 - Session 1</li>
                    <li>12:00 - Session 2</li>
                    <li>13:00 - Session 3</li>
                </ul>
            </div>
            <div className='col-span-1'>
                <ul>
                    <li>11:00 - Session 1</li>
                    <li>12:00 - Session 2</li>
                    <li>13:00 - Session 3</li>
                </ul>
            </div>
        </div>
    </div>
);

export default Schedule;