import React from 'react';
import { FaGlobe, FaLinkedin, FaTwitter } from 'react-icons/fa';
import BioModal from './modal';

const SpeakerProfiles = () => {

    // Manually defined array of speakers
    const speakers = [
        {
            id: 1,
            firstName: 'John',
            lastName: 'Doe',
            title: 'Software Engineer',
            company: 'Tech Corp',
            bio: 'John is an experienced software engineer specializing in frontend development.',
            photo: 'path/to/john-photo.jpg', // Replace with actual path or URL
            website: 'https://johndoe.com',
            linkedin: 'https://linkedin.com/in/johndoe',
            twitter: 'https://twitter.com/johndoe'
        },
        {
            id: 2,
            firstName: 'Jane',
            lastName: 'Smith',
            title: 'Data Scientist',
            company: 'Data Insights',
            bio: 'Jane is a leading expert in data science with a focus on machine learning.',
            photo: 'path/to/jane-photo.jpg',
            website: 'https://janesmith.com',
            linkedin: 'https://linkedin.com/in/janesmith',
            twitter: 'https://twitter.com/janesmith'
        },
        // Add more speakers as needed
    ];

    return (
        <section className='text-center mt-20 bg-LightBlue py-10'>
            <h2 className="my-10">Featured Speakers coming soon</h2>
            {/* <div className='w-11/12 mx-auto'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {speakers.map(speaker => (
                        <div key={speaker.id} className="p-4 bg-white rounded-lg shadow-md">
                            <img
                                src={speaker.photo}                
                                className="w-32 h-32 rounded-full mx-auto mb-4"
                            />
                            <h3 className="text-xl font-semibold">
                                {speaker.firstName} {speaker.lastName}
                            </h3>
                            <p className="text-sm">{speaker.title}, {speaker.company}</p>
                            <div className="flex justify-center mt-4">
                                {speaker.website && (
                                    <a href={speaker.website} className="mx-2" target="_blank" rel="noopener noreferrer">
                                        <FaGlobe />
                                    </a>
                                )}
                                {speaker.linkedin && (
                                    <a href={speaker.linkedin} className="mx-2" target="_blank" rel="noopener noreferrer">
                                        <FaLinkedin />
                                    </a>
                                )}
                                {speaker.twitter && (
                                    <a href={speaker.twitter} className="mx-2" target="_blank" rel="noopener noreferrer">
                                        <FaTwitter />
                                    </a>
                                )}
                            </div>
                            <BioModal speaker={speaker} />
                        </div>
                    ))}
                </div>
            </div> */}
        </section>
    );
};

export default SpeakerProfiles;
