import React from 'react';

const Volunteer = () => (

    <div>
        <h1 className='text-center my-10'>Volunteer Form</h1>
        <div className='flex flex-wrap justify-center items-center my-10'>
        <iframe title='Google Volunteer Form' src="" width="640" height="1561" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
        </div>
    </div>
    
);

export default Volunteer;
