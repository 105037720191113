import React from 'react';

const FAQ = () => (


<div className='w-11/12 mx-auto my-5 p-5'>
    <h1 className='text-center'>Frequently Asked Questions</h1>

    <h2 className='text-center'>Questions about DevFest</h2>
   
   
</div>
   
);

export default FAQ;