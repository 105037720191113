import React from "react";
import { Link } from "react-router-dom";
import GoogleLogo from "../assets/sponsors/Google_2015_logo.png";
import Me4HLogo from "../assets/sponsors/me4h_logo.jpg";
import PhDLogo from "../assets/partners/phd_logo_cropped.webp";
import TrenerSpokojuLogo from "../assets/partners/trener_spokoju_logo_cropped.png";
import PandaTeamLogo from "../assets/partners/panda_team_logo.png";

const SponsorLogos = () => (
  <div className="text-center my-20">
    <h2>Sponsors</h2>
    <p>Thank you to our sponsors for their generous support.</p>

    <section className="my-20">
      {/* <h3 className='my-10 font-semibold'>Sponsors</h3> */}
      <div className="flex flex-wrap w-full h-full px-10 justify-evenly">
        <img
          src={GoogleLogo}
          alt="Google Logo"
          className="object-contain h-36"
        ></img>
        <img
          src={Me4HLogo}
          alt="Me4H Logo"
          className="object-contain h-36"
        ></img>
      </div>
    </section>

    {/* Other sponsor sections can go here */}

    {/* Partners Section */}
    <section className="my-20">
      <h2 className="my-10 font-semibold">Partners</h2>
      <div className="flex flex-wrap gap-x-48 gap-y-8 w-full h-full px-10 justify-center">
        <img
          src={TrenerSpokojuLogo}
          alt="TrenerSpokoju Logo"
          className="object-contain max-h-28"
        ></img>
        <img
          src={PhDLogo}
          alt="PhD Logo"
          className="object-contain max-h-28"
        ></img>
        <img
          src={PandaTeamLogo}
          alt="PandaTeam Logo"
          className="object-contain max-h-24"
        ></img>
      </div>
    </section>

    <div className="m-16">
      <Link
        to="/sponsors"
        className="text-white bg-Green500 hover:text-white rounded-full text-md px-6 py-4"
      >
        Become a Sponsor
      </Link>
    </div>
  </div>
);

export default SponsorLogos;
