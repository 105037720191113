import React from "react";
import HamburgerMenu from "./hamburger.js";
import Navbar from "./navbar.js";

const Header = () => (
  <header className="bg-Grey900 py-1 top-0 sticky z-10">
    <nav className="flex justify-between items-center w-11/12 mx-auto py-3">
      <div className="header w-128 text-white text-4xl p-6">
        <span className="devfest">
          <span>D</span>
          <span>e</span>
          <span>v</span>
          <span>F</span>
          <span>e</span>
          <span>s</span>
          <span>t </span>
          <span>&nbsp; </span>
          Poznań 2024
        </span>
      </div>
      <div>
        <Navbar />
      </div>
      {/* <div className='hidden lg:block'>
                <a href="https://gdg.community.dev/" className="text-white bg-Red500 hover:bg-Red500 hover:text-white rounded-full text-md px-6 py-4 mx-2">Register</a>
            </div> */}
      <div className="">
        <HamburgerMenu />
      </div>
    </nav>
  </header>
);

export default Header;
